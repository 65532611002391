





















































import Vue from 'vue';
import xor from 'lodash/xor';
import { getFileURL, getRootNodes, loadRootNode, TreeNode } from '@/app/data';

export default Vue.extend({
    name: "ModelPicker",
    props: {
        value: Boolean,
    },

    data: () => ({
        search: '',
        tree: getRootNodes(),

        openedFolders: [] as TreeNode[],
        activeFolders: [] as TreeNode[],
        selectedFileIndex: -1,

        alert: '',
    }),
    computed: {
        activeFolderFiles() {
            return this.activeFolders.length ? this.activeFolders[0].files || [] : [];
        },
    },
    watch: {
        activeFolders(value: TreeNode[], oldValue: TreeNode[]) {
            const hasChanged = xor(value, oldValue).length !== 0;

            if (hasChanged) {
                this.selectedFileIndex = -1;
            }
        },
        selectedFileIndex() {
            this.alert = '';
        },
    },
    created() {
    },
    methods: {
        async fetchModels(node: TreeNode) {
            await loadRootNode(node);
        },
        folderOpened(openedFolders: TreeNode[]) {
            const diff = xor(openedFolders, this.openedFolders);

            if (diff.length) {
                this.activeFolders = diff.slice(0);
                this.openedFolders = openedFolders;
            } else {
                this.activeFolders = this.tree.slice(0, 1);
            }
        },
        submit() {
            if (this.activeFolders.length && this.selectedFileIndex >= 0) {
                const file = getFileURL(this.activeFolders[0], this.activeFolderFiles[this.selectedFileIndex]);

                if (file) {
                    this.$emit('select', file);
                }
            }

            this.$emit('input', false);
        },
        log(...args: any[]) {
            console.log(...args);
        },
    },
});
