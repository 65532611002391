
































import Vue from 'vue';
import { Background } from '@/tools/Background';
import { App } from '@/app/App';

export default Vue.extend({
    name: "Settings",
    data: () => ({
        volume: 0,
        hitAreaFrames: false,
        modelFrame: false,
        stats: false,
        lastUpdated: new Date(__BUILD_TIME__).toLocaleString(),
        currentBackground: Background.current,
    }),
    watch: {
        stats(value: boolean) {
            App.showStats = value;
        },
        volume(value: number) {
            App.volume = value;
        },
        hitAreaFrames(value: boolean) {
            App.showHitAreaFrames = value;
        },
        modelFrame(value: boolean) {
            App.showModelFrame = value;
        },
    },
    created() {
        this.stats = App.showStats;
        this.volume = App.volume;
        this.hitAreaFrames = App.showHitAreaFrames;
        this.modelFrame = App.showModelFrame;

        Background.emitter.on('change', this.backgroundChanged, this);
    },
    methods: {
        resetBackground() {
            Background.reset();
        },
        backgroundChanged(background: string) {
            this.currentBackground = background;
        },
    },
    beforeDestroy() {
        Background.emitter.off('change', this.backgroundChanged);
    },
});
