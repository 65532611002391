






































































































import { App } from '@/app/App';
import { Filter } from '@/app/Filter';
import { Live2DModel } from '@/app/Live2DModel';
import { ModelEntity } from '@/app/ModelEntity';
import clamp from 'lodash/clamp';
import { MotionPriority, MotionState } from 'pixi-live2d-display';
import Vue from 'vue';

interface MotionGroupEntry {
    name: string
    motions: {
        file: string;
        error?: any;
    }[]
}

interface ExpressionEntry {
    file: string;
    error?: any;
}

export default Vue.extend({
    name: 'ModelEditor',
    props: {
        id: {
            type: Number,
            default: 0,
        },
        visible: Boolean,
    },
    data: () => ({
        model: null as ModelEntity | null | undefined,

        motionExpand: false,
        motionGroups: [] as MotionGroupEntry[],
        motionState: null as MotionState | null | undefined,

        motionProgressTimerID: -1,

        expressions: [] as ExpressionEntry[],
        currentExpressionIndex: -1,
        pendingExpressionIndex: -1,

        filters: Object.keys(Filter.filters),
    }),
    computed: {
        hasPixiModel(): boolean {
            return !!this.motionState;
        },
        rotationDeg(): string {
            return Math.round((this.model?.rotation || 0) / Math.PI * 180) + '°';
        },
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                this.updateModel();
            },
        },
        'model.filters'() {
            this.model?.updateFilters();
        },

        // immediately update progress when current motion has changed
        'motionState.currentGroup': 'updateMotionProgress',
    },
    mounted() {
        this.motionProgressTimerID = setInterval(this.updateMotionProgress, 50);
    },
    methods: {
        updateModel() {
            this.resetModel();

            this.model = App.getModel(this.id);

            if (this.model) {
                if (this.model.pixiModel) {
                    this.pixiModelLoaded(this.model.pixiModel);
                } else {
                    this.model.once('modelLoaded', this.pixiModelLoaded);
                }
            }
        },
        resetModel() {
            if (this.model) {
                this.model.off('modelLoaded', this.pixiModelLoaded);
                this.model.pixiModel?.off('expressionSet', this.expressionSet);
                this.model.pixiModel?.off('expressionReserved', this.expressionReserved);
                this.model.pixiModel?.internalModel.motionManager?.off('motionLoadError', this.motionLoadError);
                this.model.pixiModel?.internalModel.motionManager?.expressionManager?.off('expressionLoadError', this.expressionLoadError);

                this.motionGroups = [];
                this.motionState = undefined;
                this.model = undefined;
            }
        },
        pixiModelLoaded(pixiModel: Live2DModel) {
            const motionManager = pixiModel.internalModel.motionManager;
            const motionGroups: MotionGroupEntry[] = [];

            const definitions = motionManager.definitions;

            for (const [group, motions] of Object.entries(definitions)) {
                motionGroups.push({
                    name: group,
                    motions: motions?.map((motion, index) => ({
                        file: motion.file || motion.File || '',
                        error: motionManager.motionGroups[group]![index]! === null ? 'Failed to load' : undefined,
                    })) || [],
                });
            }

            this.motionGroups = motionGroups;
            this.motionState = motionManager.state;

            const expressionManager = motionManager.expressionManager;
            this.expressions = expressionManager?.definitions.map((expression, index) => ({
                file: expression.file || expression.File || '',
                error: expressionManager!.expressions[index]! === null ? 'Failed to load' : undefined,
            })) || [];

            this.currentExpressionIndex = expressionManager?.expressions.indexOf(expressionManager!.currentExpression) ?? -1;
            this.pendingExpressionIndex = expressionManager?.reserveExpressionIndex ?? -1;

            pixiModel.on('expressionSet', this.expressionSet);
            pixiModel.on('expressionReserved', this.expressionReserved);
            motionManager.on('motionLoadError', this.motionLoadError);
            expressionManager?.on('expressionLoadError', this.expressionLoadError);
        },
        expressionSet(index: number) {
            this.currentExpressionIndex = index;
        },
        expressionReserved(index: number) {
            this.pendingExpressionIndex = index;
        },
        motionLoadError(group: string, index: number, error: any) {
            const motionGroup = this.motionGroups.find(motionGroup => motionGroup.name === group);

            if (motionGroup) {
                motionGroup.motions[index]!.error = error;
            }
        },
        expressionLoadError(index: number, error: any) {
            this.expressions[index]!.error = error;
        },
        startMotion(motionGroup: MotionGroupEntry, index: number) {
            this.model?.pixiModel?.motion(motionGroup.name, index, MotionPriority.FORCE);
        },
        setExpression(index: number) {
            this.model?.pixiModel?.expression(index);
        },
        updateMotionProgress() {
            if (!(this.model?.pixiModel && this.motionState?.currentGroup !== undefined && this.motionExpand && this.visible && this.$el)) {
                return;
            }

            const startTime = this.model.pixiModel.currentMotionStartTime;
            const duration = this.model.pixiModel.currentMotionDuration;
            const progress = clamp((this.model.pixiModel.elapsedTime - startTime) / duration, 0, 1);

            // using a CSS variable can be a lot faster than letting Vue update a style object bound to the element
            // since that will cause the component to re-render
            (this.$el as HTMLElement).style.setProperty('--progress', progress * 100 + '%');
        },
    },
    beforeDestroy() {
        this.resetModel();
        clearInterval(this.motionProgressTimerID);
    },
});
