





































































import Vue from 'vue';
import ModelList from './components/ModelList.vue';
import ModelCreation from './components/ModelCreation.vue';
import ModelEditor from '@/components/ModelEditor.vue';
import DropZone from '@/components/DropZone.vue';
import Settings from '@/components/Settings.vue';
import ModelInfo from '@/components/ModelInfo.vue';
import { Background } from '@/tools/Background';
import { App } from '@/app/App';

export default Vue.extend({
    name: 'App',
    components: { ModelList, ModelCreation, ModelEditor, DropZone, ModelInfo, Settings },
    data: () => ({
        drawer: true,
        drawerSwitch: false,
        loading: false,

        tab: -1 as number | undefined,

        modelList: {
            visible: true,
        },

        selectedModelID: 0,

        modelInfoDialog: false,

        creation: {
            dialog: false,
            result: null,
        },

        snackbar: {
            visible: false,
            message: '',
            timeout: 5000,
        },
    }),
    computed: {
        drawerWidth() {
            return this.$vuetify.breakpoint.xl ? 450 : 360;
        },
        modelName() {
            return App.getModel(this.selectedModelID)?.name || '';
        },
    },
    methods: {
        showUI(show: boolean) {
            this.drawer = show;
            this.modelList.visible = show;
            this.drawerSwitch = false;
        },
        snack(message: string, timeout: number = 5000) {
            this.snackbar.message = message;
            this.snackbar.timeout = timeout;
            this.snackbar.visible = true;
        },
        error(e: any) {
            const message = e && e.message || e + '';

            if (message) {
                this.snack(message, -1);
            }
        },
        log(...args: any[]) {
            console.log(...args);
        },
    },
    created() {
        // switch to the default tab
        this.tab = undefined;
        this.creation.dialog = true;

        if (!Background.current) {
            this.snack('Drag and drop a local image to set the background!');
        }
    },
});
